import React from 'react';
import { Nav } from 'containers/common/sections/layout';
import E5Adapter from 'components/adapters/E5Adapter';
import {
  EV_CALCULATOR_URL,
  EV_CHARGER_URL,
  EV_REBATES_URL,
  EV_SOFTWARE_URL,
  EV_TAX_CREDIT_URL,
  EVC_BASE_URL
} from "evcharging/app/seo/urls";
import Spacer from 'evcharging/components/Spacer';
import useI18n from "evcharging/hooks/useI18n";

const Navigation = ({ hideSubNav = false }) => {
  const { t } = useI18n();

  return (
    <E5Adapter>
      <Nav
        subNav={!hideSubNav && [
          { label: t('menu.evcharging'), href: EVC_BASE_URL },
          { label: t('menu.calculator'), href: EV_CALCULATOR_URL },
          { label: t('menu.rebates'), href: EV_REBATES_URL },
          {
            label: t('menu.product'),
            href: '#',
            sub: {
              title: t('menu.about'),
              links: [
                { label: t('menu.hardware'), href: EV_CHARGER_URL },
                { label: t('menu.software'), href: EV_SOFTWARE_URL },
              ],
            },
          },
          // { label: t('menu.projects'), href: EV_PROJECTS_URL },
          { label: t('menu.tax'), href: EV_TAX_CREDIT_URL },
        ]}
      />
      {!hideSubNav && <Spacer value={157} mobile={0} />}
    </E5Adapter>
  );
};

export default Navigation;
