import Form from 'evcharging/components/Form';
import InputError from 'evcharging/components/InputError';
import Button from 'evcharging/components/Button';
import useInput from 'evcharging/hooks/useInput';
import sendJoinConfirmation from 'evcharging/app/client/sendJoinConfirmation';
import { oneWord, trim } from 'evcharging/app/validation/filters';
import ResponsePopup from 'evcharging/components/ResponsePopup';
import Input from 'evcharging/components/Input';
import useFormResponse from 'evcharging/hooks/useFormResponse';
import Loader from 'evcharging/components/Loader';
import Textarea from 'evcharging/components/Textarea';
import Responsible from 'evcharging/components/Responsible';
import Spacer from 'evcharging/components/Spacer';
import useI18n from 'evcharging/hooks/useI18n';
import { RetailGreenCheckbox } from "../../../components/retail/structures";
import React from "react";

const RequestConsultationForm = () => {
  const name = useInput([trim]);
  const phone = useInput([trim]);
  const email = useInput([oneWord]);
  const comments = useInput([trim]);
  const agreeToReceive = useInput([trim], 'no');
  const { t } = useI18n();

  const { isLoading, onSubmit, ...popup } = useFormResponse(
    { name, email, phone, comments, agreeToReceive },
    async (values: Record<string, string>) => {
      await sendJoinConfirmation(values);
    },
  );

  return (
    <Form onSubmit={onSubmit}>
      <ResponsePopup {...popup} />
      <Input
        required
        label={t('form.lead.name')}
        placeholder={t('form.lead.name')}
        name={'name'}
        onChangeValue={name.onChangeValue}
        value={name.value}
        onBlur={() => {
          name.setError('');
        }}
        invalid={!!name.error}
      >
        <InputError message={name.error} />
      </Input>
      <Responsible fractions={[48, 48]} space={0}>
        <Input
          required
          label={t('form.lead.email')}
          placeholder={t('form.lead.email')}
          name={'email'}
          onChangeValue={email.onChangeValue}
          value={email.value}
          onBlur={() => {
            email.setError('');
          }}
          invalid={!!email.error}
        >
          <InputError message={email.error} />
        </Input>
        <Input
          label={t('form.lead.phone')}
          type={'number'}
          placeholder={t('form.lead.phone')}
          name={'phone'}
          onChangeValue={phone.onChangeValue}
          onBlur={() => {
            phone.setError('');
          }}
          value={phone.value}
          invalid={!!phone.error}
        >
          <InputError message={phone.error} />
        </Input>
      </Responsible>
      <Textarea
        placeholder={t('form.lead.type')}
        label={t('form.lead.comments')}
        name={'comments'}
        onChangeValue={comments.onChangeValue}
        onBlur={() => {
          comments.setError('');
        }}
        value={comments.value}
      />
      <RetailGreenCheckbox
        label={t('form.label.agree-receive')}
        onChange={(event) => {
          agreeToReceive.onChangeValue(event.target.checked ? 'yes' : 'no');
        }}
        checked={agreeToReceive.value === 'yes'}
      />
      <Spacer value={32} />
      <Button type="submit" disabled={isLoading} fullWidth>
        {isLoading ? <Loader /> : t('form.lead.button')}
      </Button>
    </Form>
  );
};

export default RequestConsultationForm;
