import Section from 'evcharging/containers/layout/Section';
import RequestConsultationCard from 'evcharging/containers/layout/RequestConsultationCard';
import React from 'react';

const RequestConsultation = () => {
  return (
    <Section>
      <RequestConsultationCard withBorder />
    </Section>
  );
};

export default RequestConsultation;
